html {
  scroll-behavior: smooth;
}

p {
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
}
.mainnav {
  background-color: #006e53;
  width: 100%;
}

#navbtns > div > a.active {
  background-color: rgba(0, 110, 83, 0.8) !important;
}

#sectionnav {
  margin-top: 10%;
  flex-direction: column !important;
}
.logos {
  background-color: rgba(220, 220, 220, 0.8);
  align-items: center;
  min-width: 200px;

}
.btn {
  background-color: #006E53;
  border-color: #177B63;
}
.btn:hover {
  background-color: #1e9c7d !important;
}
.btn:active {
  border-color: #177B63 !important;
}
.btn:active:after .btn:focus {
  background-color: #1e9c7d !important;
}


.btn-primary {
  color: #fff;
  background-color: #006E53 !important;
  background-color: cutom-color;
  border-color: #177B63 !important;
  border-color: cutom-cor;
}
.btnActive {
  
}

div {
  text-align: center;
}

.table {
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  max-width: 90vw;
}


.row {
  flex-direction: row;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin: 1vh;
}
.sel {
  display: block;
}
#cht {
  overflow: auto;
  font-weigth: bold;
}
#pcht {
  overflow: auto;
  font-weigth: bold;
}
#dcht {
  overflow: auto;
}
.acreDrop, .acreDrop:hover, .acreDrop:active {
    background-color: Transparent !important;
    background-repeat:no-repeat !important;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: black !important;
}
.stickyleft {
  position: absolute;
  flex-direction: column;
  min-height: 20vh;
  width: 7vh;
  transform: translate(-22.5vw);
  left: 0;
  right: auto;
  background-color: rgba(0, 110, 82, 0.8);
  cursor: pointer;
}
.sideways {
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
  display: block;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
}
.white {
  color: #fff;
}

.flat-butt {
  margin: 0 5px;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border: 0;
  color: #FFF;
  cursor: pointer;
  outline: 0;
}
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;

  top: 10px;
  left: 0;
}
.absImg {
  object-fit: contain !important;
}
.pb75 {
  padding-bottom: 60%;
}
.parallax {
 
  overflow:  hidden;
  min-height: 90vh;
  max-height: 100vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.display-4 {

  font-size: calc(1vw + 2vh + 2vmin) !important;

}
.main-subtitle {

  font-size: calc(1vw + 1vh + 1vmin);
  
}

.MuiSlider-mark {
  transform: scale(2, 3) !important;

}